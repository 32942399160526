import { FC } from 'react';
import { Controller } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import useQuoteForm, { Props, ReceivedProps } from './hook';
import { PartnerFormStyles } from './styled';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import { useTranslation } from 'react-i18next';
import quoteValidation from 'utils/validation/registers/quote';

const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const PartnerFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    setValue,
    register,
    errors,
    navigate,
    handleValidateWhitespace,
    screenType,
}) => {
    const { t } = useTranslation();
    const wordLimit = 5000;

    return (
        <PartnerFormStyles className="p-[24px]">
            <div className="bg-[#fff] quote-form-container rounded-[4px] p-[12px]">
                <form onSubmit={handleSubmit(submitData)}>
                    <div className="text-[18px] font-bold mb-[30px]">
                        顧客情報
                    </div>
                    <div className="flex gap-x-[10px] mb-[24px]">
                        <div className="w-[20%]">
                            <Controller
                                name="last_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            協力会社ID
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'last_name',
                                                quoteValidation(t).last_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.last_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.last_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[30%]">
                            <Controller
                                name="first_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            協力会社名
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'first_name',
                                                quoteValidation(t).first_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.first_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.first_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[30%]">
                            <Controller
                                name="last_name_kana"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            郵便番号
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'last_name_kana',
                                                quoteValidation(
                                                    t,
                                                ).last_name_kana(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.last_name_kana && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.last_name_kana.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[20%]">
                            <Controller
                                name="first_name_kana"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            FAX番号
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'first_name_kana',
                                                quoteValidation(
                                                    t,
                                                ).first_name_kana(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.first_name_kana && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.first_name_kana.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex gap-x-[10px] mb-[24px]">
                        <div className="w-[20%]">
                            <Controller
                                name="first_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            市/県
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'first_name',
                                                quoteValidation(t).first_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.first_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.first_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[30%]">
                            <Controller
                                name="first_name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">
                                                *
                                            </span>
                                            区/郡
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'first_name',
                                                quoteValidation(t).first_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.first_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.first_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[30%]">
                            <Controller
                                name="last_name_kana"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            住所
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'last_name_kana',
                                                quoteValidation(
                                                    t,
                                                ).last_name_kana(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.last_name_kana && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.last_name_kana.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-[20%]">
                            <Controller
                                name="first_name_kana"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            業種
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'first_name_kana',
                                                quoteValidation(
                                                    t,
                                                ).first_name_kana(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.first_name_kana && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.first_name_kana.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex gap-x-[10px] mb-[34px]">
                        <div className="flex flex-col w-[20%]">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>
                                    <span className="text-red mr-1">*</span>
                                    会社種別
                                </p>
                            </div>
                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={statusOptions}
                                        search
                                        filterOptions={handleFilter}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                        disabled={screenType === 'detail'}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex flex-col w-[40%]">
                            <div className="input-add-user d-flex flex-column">
                                <p style={{ marginTop: 0 }}>
                                    <span className="text-red mr-1">*</span>
                                    分類
                                </p>
                            </div>
                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <SelectSearch
                                        options={statusOptions}
                                        search
                                        filterOptions={handleFilter}
                                        {...field}
                                        emptyMessage={'該当なし'}
                                        placeholder=""
                                        disabled={screenType === 'detail'}
                                    />
                                )}
                            />
                        </div>
                        <div className="w-[40%]">
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field }) => (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            紹介者
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                            {...register(
                                                'last_name',
                                                quoteValidation(t).last_name(),
                                            )}
                                            onBlur={handleValidateWhitespace}
                                            disabled={screenType === 'detail'}
                                        ></input>
                                        {errors.last_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.last_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="border border-solid border-[#000000] px-[12px] py-[12px]">
                        <p className="font-medium mt-[-26px] w-fit bg-[#ffffff] px-[8px] !mb-[6px]">
                            代表者情報
                        </p>
                        <div className="flex gap-x-[10px] mb-[12px]">
                            <div className="w-[22%]">
                                <Controller
                                    name="last_name"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                氏名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'last_name',
                                                    quoteValidation(
                                                        t,
                                                    ).last_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                            ></input>
                                            {errors.last_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[22%]">
                                <Controller
                                    name="first_name"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                役職
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'first_name',
                                                    quoteValidation(
                                                        t,
                                                    ).first_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                            ></input>
                                            {errors.first_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[22%]">
                                <Controller
                                    name="last_name_kana"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                電話番号
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'last_name_kana',
                                                    quoteValidation(
                                                        t,
                                                    ).last_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                            ></input>
                                            {errors.last_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[22%]">
                                <Controller
                                    name="first_name_kana"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                メール
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'first_name_kana',
                                                    quoteValidation(
                                                        t,
                                                    ).first_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    screenType === 'detail'
                                                }
                                            ></input>
                                            {errors.first_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-[12%]">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        敬称
                                    </p>
                                </div>
                                <Controller
                                    name="status"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <SelectSearch
                                            options={statusOptions}
                                            search
                                            filterOptions={handleFilter}
                                            {...field}
                                            emptyMessage={'該当なし'}
                                            placeholder=""
                                            disabled={screenType === 'detail'}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-[16px]">
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => (
                                <div className="d-flex flex-column">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        コメント
                                    </p>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                        {...register(
                                            'last_name',
                                            quoteValidation(t).last_name(),
                                        )}
                                        onBlur={handleValidateWhitespace}
                                        disabled={screenType === 'detail'}
                                    ></input>
                                    {errors.last_name && (
                                        <span className="error text-xs tracking-tight mt-1">
                                            {errors.last_name.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <div className="input-add-user d-flex flex-column">
                            <p style={{ marginTop: 0 }}>
                                <span className="text-red mr-1">*</span>
                                担当者
                            </p>
                        </div>
                        <Controller
                            name="status"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <SelectSearch
                                    options={statusOptions}
                                    search
                                    filterOptions={handleFilter}
                                    {...field}
                                    emptyMessage={'該当なし'}
                                    placeholder=""
                                    disabled={screenType === 'detail'}
                                />
                            )}
                        />
                    </div>

                    <div className="text-lg flex flex-row flex-wrap gap-[10px] mt-[34px]">
                        <button
                            className="modal-create-user-button1 !text-[#000000D9] d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="button"
                            onClick={() => navigate('/partners')}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="submit"
                        >
                            保存
                        </button>
                    </div>
                </form>
            </div>
        </PartnerFormStyles>
    );
};

const PartnerForm: FC<ReceivedProps> = (props) => {
    return <PartnerFormLayout {...useQuoteForm(props)} />;
};

export default PartnerForm;
